import { useEoValue } from '~/src/hooks/useEoState'
import { FIRM_THEME_SELECTOR } from '~/src/stores/firm'
import { NavigationThemeFlowEnum } from '@eo-storefronts/eo-core'
import ProductsPage from '~/src/pages/products'
import CategoriesPage from '~/src/pages/categories'

const Menu = () => {
  const firmTheme = useEoValue(FIRM_THEME_SELECTOR)

  if (firmTheme?.flow?.productListNavigation === NavigationThemeFlowEnum.PRODUCTS) {
    return <ProductsPage />
  }

  return <CategoriesPage />
}

export default Menu
